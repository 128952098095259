var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Contravales","to":{ name: 'voucher-home' }}}),_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"2"}},[_c('company-field',{attrs:{"returnId":true},on:{"value":function (val) { return (_vm.companyId = val); }},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":[
          { text: 'Por Mes', value: 0 },
          { text: 'Por Fecha', value: 1 } ],"label":"Mostrar Datos"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),(_vm.searchType === 1)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Fecha"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Fecha","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('provider-field',{attrs:{"available":true},model:{value:(_vm.providerId),callback:function ($$v) {_vm.providerId=$$v},expression:"providerId"}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.search()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-database-search")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo","to":{ name: 'voucher-create' }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.summary()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-table")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.displayVoucher(item.id)}}},[_vm._v(_vm._s(item.code))])]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.date)))]}},{key:"item.is_paid",fn:function(ref){
        var item = ref.item;
return [(item.is_cancel)?_c('div',[_vm._v("Anulado")]):_c('div',[(item.is_paid)?_c('span',[_vm._v("Cancelado")]):_c('span',[_vm._v("Pendiente de Pago")])])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}}],null,true)}),(_vm.dialog)?_c('voucher-detail',{attrs:{"dialog":_vm.dialog,"voucher":_vm.voucher},on:{"close":_vm.closeDialog}}):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 0),expression:"totalPages > 0"}],attrs:{"total-visible":10,"length":_vm.totalPages,"disabled":_vm.loading},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('summary-voucher',{attrs:{"dialog":_vm.summaries},on:{"close":function($event){_vm.summaries = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }