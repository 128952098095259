<template>
  <v-container fluid>
    <head-title text="Contravales" :to="{ name: 'voucher-home' }"></head-title>
    <v-row>
      <v-col cols="3" md="2">
        <company-field
          v-model="companyId"
          @value="(val) => (companyId = val)"
          :returnId="true"
        ></company-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          :items="[
            { text: 'Por Mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 1">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-else>
        <date-field label="Fecha" v-model="date" type="month"></date-field>
      </v-col>
      <v-col cols="12" md="2">
        <provider-field v-model="providerId" :available="true"></provider-field>
      </v-col>
      <v-col cols="12" md="1" class="text-end">
        <v-btn class="mx-1" fab dark small color="indigo" @click="search()">
          <v-icon dark>mdi-database-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="indigo"
          :to="{ name: 'voucher-create' }"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn class="mx-2" fab dark small color="indigo" @click="summary()">
          <v-icon dark>mdi-file-table</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="list"
      :loading="loading"
      hide-default-footer
      disable-pagination
      disable-sort
      class="elevation-1"
    >
      <template v-slot:[`item.code`]="{ item }">
        <v-btn text color="primary" @click="displayVoucher(item.id)">{{
          item.code
        }}</v-btn>
      </template>
      <template v-slot:[`item.date`]="{ item }">{{
        item.date | date
      }}</template>
      <template v-slot:[`item.is_paid`]="{ item }">
        <div v-if="item.is_cancel">Anulado</div>
        <div v-else>
          <span v-if="item.is_paid">Cancelado</span>
          <span v-else>Pendiente de Pago</span>
        </div>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount | currency }}
      </template>
    </v-data-table>
    <voucher-detail
      :dialog="dialog"
      :voucher="voucher"
      v-if="dialog"
      @close="closeDialog"
    ></voucher-detail>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :total-visible="10"
        :length="totalPages"
        :disabled="loading"
        v-show="totalPages > 0"
      ></v-pagination>
      <summary-voucher
        :dialog="summaries"
        @close="summaries = false"
      ></summary-voucher>
    </div>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
export default {
  name: "VoucherList",
  components: {
    CompanyField: () => import("@/components/fields/CompanyField"),
    VoucherDetail: () => import("@/components/vouchers/Detail"),
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    DateField: () => import("@/components/fields/DateField"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    SummaryVoucher: () => import("@/components/vouchers/SummaryVoucher"),
  },
  data() {
    return {
      summaries: false,
      currentPage: 1,
      totalPages: 0,
      dialog: false,
      loading: false,
      providerId: undefined,
      companyId: "",
      date: "",
      year: null,
      month: null,
      searchType: null,
      voucher: {},
      list: [],
      headers: [
        { text: "No.", value: "code" },
        { text: "Empresa", value: "_company" },
        { text: "Proveedor", value: "_provider" },
        { text: "Número de Vale", value: "voucher_number" },
        { text: "Fecha", value: "date" },
        { text: "Total", value: "amount", align: "right" },
        { text: "Estado", value: "is_paid", align: "end" },
      ],
    };
  },
  mounted() {
    this.date = new Date().toISOString().substring(0, 10);
    this.searchType = 1;
  },
  methods: {
    summary() {
      this.summaries = true;
    },
    getList(uri) {
      this.loading = true;
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.list = res.data;
          this.totalPages = res.total_pages;
          this.loading = false;
        } else {
          console.log(res.data);
        }
      });
    },
    displayVoucher(item) {
      this.voucher = item;
      this.dialog = true;
    },
    closeDialog(updated) {
      this.dialog = false;
      if (updated) {
        this.search();
      }
    },
    search() {
      var uri = "";
      if (this.companyId == null || this.companyId == 0) {
        this.$toasted.global.info({
          message: "Seleccione una Empresa",
        });
        return;
      }
      if (this.date == null) {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
        return;
      }

      if (this.searchType == 1) {
        uri =
          "api/vouchers/?page=" +
          this.currentPage +
          "&date=" +
          this.date +
          "&company=" +
          this.companyId;
      } else if (this.searchType == 0) {
        uri =
          "api/vouchers/?page=" +
          this.currentPage +
          "&year=" +
          this.date.substring(0, 4) +
          "&month=" +
          this.date.substring(5, 7) +
          "&company=" +
          this.companyId;
      }
      if (this.providerId != undefined) {
        uri += "&provider_id=" + this.providerId.id;
      }
      this.getList(uri);
    },
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
    },
  },
};
</script>
